/* div#editView input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #e8f5e9;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

div#editView input[type="text"]:focus {
  border: 3px solid #4caf50;
} */

div#editView select {
  width: 40%;
  padding: 8px 16px;
  margin: 8px 8px;
  background-color: white;
  border: 1px solid #e8f5e9;
}
