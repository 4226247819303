div.home-steps {
  display: flex;
  align-items: center;
}

div.home-steps-child {
  display: flex;
  flex-direction: column;
}

h2.homepage{
  font-weight: normal;
}

h2.homepage span{
  font-weight: bold;
}