* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f3f3f3;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 708px;
  overflow-y: auto;
  /* overflow: hidden; */
  margin: 0 auto;
  padding: 15px;
}

.flexbox .field {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin-left: 15px;
  background-color: #c8e6c9;
  padding: 15px;
}

.flexbox .field .item {
  padding: 15px;
  height: 50px;
  background-color: #f3f3f3;
  cursor: pointer;
  margin-bottom: 15px;
}
